
import VueTypes from 'vue-types';
import newsletterSubscribe from '@/gql/queries/globals/newsletterSubscribe.gql';

export default {
  inheritAttrs: false,
  props: {
    colorScheme: VueTypes.string.def('blue'),
  },
  data() {
    return {
      newsLetter: null,
    };
  },
  async fetch() {
    const { newsLetter } = await this.$cms.query({
      query: newsletterSubscribe,
      variables: {
        site: this.$site,
      },
    });
    this.newsLetter = newsLetter;
  },
};
