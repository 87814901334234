
import VueTypes from 'vue-types';
import colors from '~/_tailwind/tailwind.config.colors';

export default {
  inheritAttrs: false,
  props: {
    colorScheme: VueTypes.string.def('blue'),
  },
  computed: {
    fillColor() {
      return (
        {
          aubergine: colors.aubergine.default,
          green: colors.green.default,
          blue: colors.blue.dark,
          'dark-blue': '#00b4db',
        }[this.colorScheme] || ''
      );
    },
    gradientColor() {
      return (
        {
          'dark-blue': '#0083b0',
        }[this.colorScheme] || ''
      );
    },
    strokeColor() {
      return (
        {
          'light-blue': colors.white,
          orange: colors.white,
        }[this.colorScheme] || ''
      );
    },
  },
};
