
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    fillColor: VueTypes.string.def(''),
    gradientColor: VueTypes.string.def(''),
    strokeColor: VueTypes.string.def(''),
  },
};
