import { render, staticRenderFns } from "./Banner.vue?vue&type=template&id=5ff1dc89&scoped=true"
import script from "./Banner.vue?vue&type=script&lang=js"
export * from "./Banner.vue?vue&type=script&lang=js"
import style0 from "./Banner.vue?vue&type=style&index=0&id=5ff1dc89&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff1dc89",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseShape: require('/workspace/app/components/icons/BaseShape.vue').default,Container: require('/workspace/app/components/layout/Container.vue').default,Module: require('/workspace/app/components/layout/Module.vue').default})
